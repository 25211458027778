import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import axios from "axios";
import ProgressBar from "@ramonak/react-progress-bar";

const S3_BUCKET = "mdarasa";
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: "AKIARXO52OQVTKRJJ2O5",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const PDFUploads = (props) => {
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [text, setValue] = useState("");

  const topic = props.topic;
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  useEffect(() => {
    getAttachments();
  }, []);
  const uploadFile = (file) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: file.name,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
        if (evt.loaded == evt.total) {
          axios
            .post(`/attachments.json`, {
              the_model_name: "topics",
              identifier_id: topic.id,
              name: text,
              file_type: "pdf",
              file_url: `https://mdarasa.s3.amazonaws.com/${file.name}`,
              file_status: "active",
            })
            .then((res) => {
              getAttachments();
              setProgress(0);
              setSelectedFile(null);
            });
        }
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };
  //GET ATTACHMENTS
  const getAttachments = () => {
    axios
      .get(`/attachments.json?identifier_id=${topic.id}&from=topics`)
      .then((res) => {
        console.log(res.data);
        setAttachments(res.data);
      });
  };
  const deleteFile = (id) => {
    axios.delete(`/attachments/${id}.json`).then((res) => {
      getAttachments();
    });
  };
  return (
    <div className="col-md-12">
      <h3>PDFS UPLOADS</h3>
      <div className="col-md-12">
        <div className="col-md-8">
          <input type="file" onChange={handleFileInput} />
          <br />
          <input
            type="text"
            className="form-control"
            placeholder="PDF Caption"
            value={text}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <button
            className="btn btn-primary"
            onClick={() => uploadFile(selectedFile)}
          >
            Upload PDF
          </button>
        </div>
        <div className="col-md-12" style={{ padding: 10 }}>
          {progress >= 1 && (
            <ProgressBar completed={progress} maxCompleted={100} />
          )}
        </div>
      </div>
      <div className="col-md-12" style={{ padding: 10 }}>
        {attachments.map((attachment) => {
          return (
            <div className="col-md-4">
              <div
                className="col-md-12"
                style={{
                  border: "1px solid rgba(0,0,0,0.05)",
                  borderRadius: 5,
                  margin: 5,
                  textAlign: "center",
                  padding: 10,
                }}
              >
                <a target="_blank" href={attachment.file_url}>
                  <img
                    style={{ width: "80px", height: "80px" }}
                    src="https://mdarasa.s3.amazonaws.com/icon.png"
                  />
                  <p style={{ color: "black" }}>{attachment.name}</p>
                </a>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => deleteFile(attachment.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PDFUploads;
